<template>
  <div class="family-printer">
    <a-card class="floating-table">
      <wiser-icon icon="close" class="float-end" @click="close"></wiser-icon>
      <h2 class="subtitle text-center">
        {{ $t("generic.titles.select_family") }}
      </h2>
      <div v-if="basketStore.loadingFamily" class="loading-icon">
        <a-spin :tip="t('auth.loading')"></a-spin>
      </div>

      <full-table
        ref="tableRef"
        :columnDefs="configColumns"
        :rowData="familyFunds"
        :pageSize="10"
        @selection-changed="selectionChanged"
        v-else
      />
      <div class="row justify-content-center mt-2">
        <sdButton
          class="ant-btn ant-btn-secondary mx-1 col-4 col-md-1"
          :disabled="!selectedItems.length"
          @click="addSelected()"
        >
          {{ $t("generic.buttons.add") }}
        </sdButton>
        <sdButton
          class="ant-btn ant-btn-secondary mx-1 col-4 col-md-1"
          :disabled="!selectedItems.length"
          @click="replaceWithSelected()"
        >
          {{ $t("generic.buttons.replace") }}
        </sdButton>
      </div>
    </a-card>
  </div>
</template>

<script setup>
import { ref, defineEmits, defineProps, computed } from "vue";
import { useBasketStore } from "@/stores/BasketStore";
import WiserIcon from "@/components/icons/wiser-icons";
import { useI18n } from "vue-i18n";
import { useAuthStore } from "@/stores/AuthStore";
import FullTable from "@/components/wiseral/FullTable";

const authStore = useAuthStore();
const { t, n } = useI18n();

const basketStore = useBasketStore();
const tableRef = ref(null);

const props = defineProps({
  selectedFamily: { type: Number, required: true },
});

const configColumns = computed(() => {
  return [
    {
      field: "isin_tkr",
      headerName: t(`search_funds.select_family_columns.isin`),
      headerTooltip: t(`search_funds.select_family_columns.isin`),
      minWidth: 120,
    },
    {
      field: "name",
      headerName: t(`search_funds.select_family_columns.name`),
      headerTooltip: t(`search_funds.select_family_columns.name`),
      minWidth: 200,
    },
    ...authStore.config.columas_selector_familia.map((col) => ({
      headerName: t(`search_funds.select_family_columns.${col}`, col),
      headerTooltip: t(`search_funds.select_family_columns.${col}`, col),
      field: col,
      minWidth: 100,
    })),
    {
      headerName: t(`search_funds.select_family_columns.tradable`),
      headerTooltip: t(`search_funds.select_family_columns.tradable`),
      field: "tradable",
      minWidth: 100,
    },
  ];
});

const familyFunds = computed(() => {
  return basketStore.familyFunds.map((isin_tkr) => {
    const row = {};
    Object.keys(isin_tkr).map(key => {
      const format = basketStore.columns.find(col => col.key === key)?.format.format;
      const decimalQty = basketStore.columns.find(col => col.key === key)?.format.decimalQty || 0;
      if (format === 'boolean' || key === 'tradable') {
        row[key] = isin_tkr[key]
        ? t(`search_funds.select_family_columns.yes`)
        : t(`search_funds.select_family_columns.no`);
      } else if (["decimal", "percent"].includes(format)) {
        row[key] = n(Number(isin_tkr[key]), format, {
          minimumFractionDigits: decimalQty,
        });
      } else {
        row[key] = isin_tkr[key];
      }
    });
    return row;
  });
});

const selectedItems = ref([]);

const selectionChanged = () => {
  selectedItems.value = tableRef.value?.getSelectedRows() || [];
};

const addSelected = () => {
  emit("addSelected", { selectedFunds: selectedItems.value });
};
const replaceWithSelected = () => {
  emit("replaceWithSelected", {
    selectedFunds: selectedItems.value,
    selectedFamily: props.selectedFamily,
  });
};

const emit = defineEmits(["closeFamily", "replaceWithSelected", "addSelected"]);
const close = () => {
  emit("closeFamily");
};
</script>

<style lang="scss">
.family-printer {
  background-color: rgba(217, 217, 217, 0.7);
  position: fixed;
  top: 0px;
  padding: 10vh 10vw;
  width: 100vw !important;
  height: 100vh;
  left: 0px;
  z-index: 9999;
  .wiser-icon {
    &.close {
      background-color: #004851;
      cursor: pointer;
      width: 2em;
      height: 2em;
    }
  }
  .floating-table {
    max-height: 100%;
    overflow-y: auto;
  }
}

.loading-icon {
  display: flex;
  justify-content: center;
  margin: 50px 0;
}
</style>
