<template>
  <TableGraphSwitcher @change-viewmode="changeView" />
  <div id="draw-basket-graph" :style="{ display: isGraph ? 'block' : 'none', 'margin-top': '36px' }">
    <basket-graph
      :graph="fundsStore.getDynamicGraph"
      :ploted="basketStore.getPlotedValues"
    ></basket-graph>
  </div>
  <a-row class="mt-3" :style="{ display: isGraph ? 'none' : 'block' }">
    <a-col :sm="24" :xs="24">
      <div class="portfolio-basket-funds">
        <basket-table :assetType="assetType" :isGraph="isGraph"></basket-table>
      </div>
    </a-col>
  </a-row>
</template>

<script setup>
import { defineProps, ref } from "vue";
import BasketGraph from "@/components/wiseral/BasketGraph.vue";
import BasketTable from "./BasketTable.vue";
import TableGraphSwitcher from "../TableGraphSwitcher.vue";
import { useFundsStore } from "@/stores/FundsStore";
import { useBasketStore } from "@/stores/BasketStore";

// eslint-disable-next-line no-undef
defineProps({
  assetType: {
    type: String,
    default: function () {
      return "";
    },
  },
});
const fundsStore = useFundsStore();
const basketStore = useBasketStore();

const isGraph = ref(false);

const changeView = (e) => {
  isGraph.value = e;
};
</script>

<style scoped lang="scss">
.portfolio-basket-funds {
  .switcher {
    margin-bottom: 20px;
  }
}
</style>
