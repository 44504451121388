<template>
  <div class="action-icons">
    <WiserIcon
      v-if="benchmarked"
      class="icon-icon benchmark"
      :class="{ disabled: loading }"
      :tooltip="$t('generic.buttons.benchmark')"
      @click="addBenchmark"
    ></WiserIcon>
    <WiserIcon
      disable
      v-else
      class="icon-icon no-benchmark"
      :class="{ disabled: loading }"
      :tooltip="$t('generic.buttons.benchmark')"
      @click="addBenchmark"
    ></WiserIcon>
    <WiserIcon
      disable
      v-if="params?.data?.family"
      :class="'icon-icon plus-outline'"
      :tooltip="$t('generic.buttons.family')"
      @click="addFamily"
    ></WiserIcon>
  </div>
</template>

<script setup>
import { defineProps, computed, ref } from "vue";
import { usePortfolioStore } from "../../stores/PortfolioOverviewStore";
import WiserIcon from "../icons/wiser-icons.vue";

// eslint-disable-next-line no-unused-vars
const portfolioStore = usePortfolioStore();

const props = defineProps({
  params: {
    type: Object,
  },
});

const loading = ref(false);

const addFamily = (event) => {
  event.preventDefault();
  event.stopPropagation();
  props.params.addFamilyAction(props.params.value);
};

const addBenchmark = async (event) => {
  event.preventDefault();
  event.stopPropagation();
  if (loading.value) {
    return;
  }
  loading.value = true;
  await props.params.toggleBenchmarkAction(props.params.value);
  loading.value = false;
};

const benchmarked = computed(() =>
  portfolioStore.isActiveBenchmark(props.params.value)
);
</script>

<style lang="scss">
.action-icons {
  // border: 1px red solid;
  // position: static;
  // z-index: 9999;
  // max-height: 100%;
  padding: 0;
  padding-top: 5px;
  display: flex;
  justify-content: right;
  gap: 2px;
  .wiser-icon {
    transform-origin: 50% 50%;
    scale: 0.9;
    &.plus-outline {
      scale: 1;
    }
  }
}
</style>
